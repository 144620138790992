const svg = (w: number | string, h: number | string) => `
<svg 
  width="${w}" 
  height="${h}" 
  version="1.1" 
  xmlns="http://www.w3.org/2000/svg" 
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <defs>
    <linearGradient id="g">
      <stop stop-color="currentColor" offset="10%" stop-opacity="0" />
      <stop stop-color="currentColor" offset="50%" stop-opacity="100" />
      <stop stop-color="currentColor" offset="90%" stop-opacity="0"/>
    </linearGradient>
  </defs>
  <rect width="${w}" height="${h}" fill="#FFF0" />
  <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
  <animate 
    xlink:href="#r" 
    attributeName="x" 
    from="-${w}" 
    to="${w}" 
    dur="1s" 
    repeatCount="indefinite"  
  />
</svg>`;

const toBase64 = (str: string) =>
  typeof window === 'undefined' ? Buffer.from(str).toString('base64') : window.btoa(str);

const generateImagePlaceholder = (w: number | string, h: number | string) =>
  `data:image/svg+xml;base64,${toBase64(svg(w, h))}`;

export default generateImagePlaceholder;
