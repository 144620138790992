import cn from 'classnames';
import Image, { ImageProps } from 'next/image';
import { FC } from 'react';

import generateImagePlaceholder from '~utils/generateImagePlaceholder';

import { Attachment } from '~types';

import s from './ShadowImage.module.css';

export interface ShadowImageProps extends Partial<ImageProps> {
  data: Partial<Attachment>;
  version?: 'thumbnail' | 'url';
  className?: string;
}

const ShadowImage: FC<ShadowImageProps> = ({
  data,
  className,
  version = 'url',
  alt,
  ...imageProps
}) => {
  const defaultProps: ImageProps = {
    src: data?.[version] || '',
    fill: true,
    className: s.img,
    alt: '',
  };
  return (
    <div className={cn(s.root, className)}>
      <Image
        {...defaultProps}
        {...imageProps}
        alt={alt || `${data?.filename}`}
        placeholder="blur"
        blurDataURL={generateImagePlaceholder(imageProps?.width || 700, imageProps?.height || 475)}
      />
    </div>
  );
};

export default ShadowImage;
