import dayjs from 'dayjs';
import type { GetStaticProps, InferGetStaticPropsType, NextPage } from 'next';
import { CarouselJsonLd } from 'next-seo';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import Billboard from '~components/Billboard';
import type { AdsBubblesType } from '~components/common/AdsBubbles/AdsBubbles';
import Banners from '~components/common/Banners';
import Layout from '~components/ui/Layout';
import Loading from '~components/ui/Loading';
import { multipleReq } from '~utils/api/common';
import useBillboard from '~utils/hooks/useBillboard';
import normalizeAds, { NormalizedAd } from '~utils/normalizers/normalizeAds';
import normalizeBanners from '~utils/normalizers/normalizeBanners';
import normalizeBillboard, { NormalizedBillboard } from '~utils/normalizers/normalizeBillboard';
import normalizeComingSoon, { NormalizedComingSoon } from '~utils/normalizers/normalizeComingSoon';

import { Banner } from '~types';

import s from '../styles/pages/Home.module.css';

const AdsBubbles = dynamic(() => import('~components/common/AdsBubbles'), {
  ssr: false,
}) as AdsBubblesType;

const BillboardFilters = dynamic(() => import('~components/Billboard/BillboardFilters'), {
  ssr: process.env.NODE_ENV === 'production',
}) as any;
const ComingSoon = dynamic(() => import('~components/Movies/ComingSoon'), {
  ssr: process.env.NODE_ENV === 'production',
}) as any;

export interface GSProps {
  banners: Banner[];
  initialBillboard: NormalizedBillboard[];
  initialDates: string[];
  comingSoon: NormalizedComingSoon[];
  ads: NormalizedAd[];
}

/**
 * Se genera una página estática por cada complejo y por medio de _middleware se hace un rewrite
 * del home según el valor de la cookie "cinema" la cual se crea cuando el usuario selecciona un
 * complejo.
 *
 * De esta manera cuando los usuarios que ya habían seleccionado un complejo, cuando vuelven al home
 * se les muestra la cartelera del cine en la cookie.
 *
 * por este motivo, esta función es sobreescrita en /billboard/[CinemaId]
 *
 * En el home inicial se debe mostrar una cartelera global, es decir, se muestran todas las
 * películas exhibidas en todos los complejos
 */
export const getStaticProps: GetStaticProps<GSProps> = async () => {
  const { banners, cinemas, initialBillboard, comingSoon, dates, ads } = await multipleReq({
    banners: `/banners`,
    cinemas: '/cinemas/list',
    initialBillboard: `/shows`,
    comingSoon: `/movies/coming-soon`,
    dates: `/shows/dates`,
    ads: '/ads/public',
  });
  // const dates = generateBillboardDates(billboard);
  return {
    props: {
      banners: normalizeBanners(banners),
      initialBillboard: normalizeBillboard(initialBillboard),
      initialDates: dates.map((item: { date: string }) => item.date),
      comingSoon: normalizeComingSoon(comingSoon),
      ads: normalizeAds(ads),
      fallback: {
        '/cinemas/list': cinemas,
      },
    },
    revalidate: 60, // 60 seconds
  };
};

/**
 * Esta sección se comparte con las páginas estáticas de la cartelera de cada complejo.
 * /billboard/[CinemaId]
 */
type Props = InferGetStaticPropsType<typeof getStaticProps>;
const Home: NextPage<Props> = ({ banners, initialBillboard, initialDates, comingSoon, ads }) => {
  const jsonLdForMovies =
    Array.isArray(initialBillboard) &&
    initialBillboard.map((movie: NormalizedBillboard) => {
      return {
        name: movie.title,
        url: `${process.env.NEXT_PUBLIC_APP_URL}/pelicula/${movie.slug}`,
        image: movie.poster?.thumbnail,
      };
    });
  const { query } = useRouter();
  const { billboard, dates, loading } = useBillboard({ initialBillboard, initialDates });
  const CinemaId = (query?.c || query?.CinemaId) as string;
  const date = (query?.d || query?.date || dates?.[0]) as string;
  return (
    <Layout
      meta={{
        title: 'Cartelera | Citicinemas',
        description: 'Citicinemas, la experiencia y emoción del cine',
      }}
    >
      <CarouselJsonLd ofType="movie" data={jsonLdForMovies} />
      {!!banners?.length && <Banners data={banners} />}
      <div className="container">
        <div className={s.content}>
          <h1 className={s.tagLine}>La experiencia y emoción del cine.</h1>
          <p className={s.tagLineSupport}>
            Consulta nuestra cartelera y no te pierdas de nuestro próximos estrenos, promociones y
            descuentos
          </p>
        </div>
        <Loading show={loading} size="large" className="!bg-white/50 dark:!bg-neutral-900/50">
          <BillboardFilters
            cinema={CinemaId}
            date={(query?.d || query?.date || dayjs().format()) as string}
            dates={dates || initialDates}
            label="En cartelera:"
            baseHrefPath="/cartelera-"
            className={s.filters}
          />
        </Loading>
        <Billboard movies={billboard || initialBillboard} date={date} cinema={CinemaId} />
      </div>
      {!!comingSoon?.length && <ComingSoon data={comingSoon} />}
      {!!ads?.length && <AdsBubbles ads={ads} />}
    </Layout>
  );
};

export default Home;
